import React, { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'common/styles';
import { SpacingProps } from './withStyleSystem/withSpacingStyles';
import { ColorKey } from './theme';

const Container = styled(Flex)<{
  compact?: boolean, customPadding?: string,
  customMargin?: string,
  customRadius?: string
}>(({ theme, compact, customPadding, customRadius }) => ({
  backgroundColor: !compact ? theme.rgba(theme.palette.background[400][0], 0.5) : undefined,
  borderRadius: customRadius ? theme.radius.default : theme.radius.medium,
  height: '100%',
  padding: !compact ? theme.spacing(1) : undefined,
  [theme.breakpoints.down('md')]: {
    padding: customPadding ? customPadding : undefined,
  },
}));

const TimeDigits = styled.div<{ width?: string, responsiveWidth?: string }>(({ theme, width, responsiveWidth }) => ({
  display: 'inline-block',
  width: responsiveWidth ? responsiveWidth : '1.5rem',
  textAlign: 'center',
  ...theme.font.responsiveSize(2),
  fontWeight: theme.font.weight.bold,
  marginTop: '.15em',
  whiteSpace: 'nowrap',
  '& small': {
    marginTop: '.15em',
    fontSize: '.65em',
    color: theme.palette.foreground[100],
    fontWeight: theme.font.weight.medium,
  },
  [theme.breakpoints.up('md')]: {
    width: width ? width : '2.5rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.75rem',
  },
}));

const Separator = styled.div<{ color?: ColorKey, paddingY?: string }>(({ theme, color = 'primary', paddingY }) => ({
  ...theme.font.responsiveSize(3),
  display: 'inline-block',
  lineHeight: theme.font.lineHeight.compact,
  fontWeight: theme.font.weight.bold,
  color: theme.palette[color][900][0],
  padding: paddingY ? `0 ${paddingY}` : '',
}));

type TimeLabel = 'seconds' | 'minutes' | 'hours' | 'days';
type TimeDisplay = [TimeLabel, string][];

const formattedDiff = (diff: number, compact?: boolean): TimeDisplay => {
  if (diff < 0) {
    const defaultValue: TimeDisplay = [
      ['seconds', '00'],
      ['minutes', '00'],
      ['hours', '00'],
    ];
    return compact ? defaultValue : [...defaultValue, ['days', '00']];
  }

  const dd = Math.floor(diff / (60 * 60 * 24));
  const hh = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
  const mm = Math.floor((diff % (60 * 60)) / 60);
  const ss = Math.floor(diff % 60);

  const value: TimeDisplay = [
    ['seconds', String(ss).padStart(2, '0')],
    ['minutes', String(mm).padStart(2, '0')],
    ['hours', String(hh).padStart(2, '0')],
  ];

  return compact ? value : [...value, ['days', String(dd).padStart(2, '0')]];
};

export default function CountdownTimer({ value, compact, title, timeDigitsWidth,
  timeDigitsWidthResponsiveWidth,
  containerCustomPadding,
  containerCustomRadius,
  onFinishTimer,
  separatorPaddingY, ...spacingProps }: Props) {
  const [state, setState] = useState(value);
  const [finished, setFinishedState] = useState(false);

  useEffect(() => {
    setState(value);
  }, [value]);

  useEffect(() => {
    const TimeoutId = setTimeout(() => {
      setState((prev) => {
        const newState = prev - 1;
        if (newState > 0) return newState;
        clearTimeout(TimeoutId);
        return 0;
      });
    }, 1000);
    return () => clearTimeout(TimeoutId);
  }, [state]);

  const timeDisplay = formattedDiff(state, compact);

  useEffect(() => {
    if (timeDisplay[0][1] === '00' && timeDisplay[1][1] === '00' && timeDisplay[2][1] === '00') {
      setFinishedState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDisplay]);

  useEffect(() => {
    if (finished && onFinishTimer) {
      onFinishTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finished]);

  return (
    <Container compact={compact} {...spacingProps}
      customPadding={containerCustomPadding}
      customRadius={containerCustomRadius}
      align="center" justify={{ xs: 'space-between', md: 'flex-start' }}>
      {title && (
        <Text variant="body1" ml={{ xs: 1, md: 2 }} mr={!compact ? 2 : undefined}>
          {title}
        </Text>
      )}
      <Box>
        {timeDisplay.map(([label, value], i) => (
          <Fragment key={label}>
            <TimeDigits width={timeDigitsWidth}
              responsiveWidth={timeDigitsWidthResponsiveWidth}
            >
              {value}
            </TimeDigits>
            {i < timeDisplay.length - 1 && <Separator paddingY={separatorPaddingY} color={compact ? 'foreground' : 'primary'}>:</Separator>}
          </Fragment>
        ))}
      </Box>
    </Container>
  );
}

type Props = {
  value: number; // the value of the timer in seconds
  compact?: boolean;
  title?: string;
  timeDigitsWidth?: string
  timeDigitsWidthResponsiveWidth?: string,
  separatorPaddingY?: string
  containerCustomPadding?: string,
  containerCustomRadius?: string,
  onFinishTimer?: () => void
} & SpacingProps;
