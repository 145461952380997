import axios, { AxiosInstance, AxiosResponse } from 'axios';
// import { GlobalState } from 'common/CacheProvider';
// import { getUniqId } from 'common/utils/getDeviceInfo';
// import lscache from 'lscache';
import qs from 'qs';
import {
  Content,
  ResponseContainer,
  ContentRequestParams,
  // VideoArtist,
  Category,
  CategoryList,
  HyperLinkMenuList,
  ApplicationsConfigurations,
  Widget,
  Comment,
  Person,
  // SurveyGroup,
  ContentAggregate,
  CinemaTicketInvoice,
  CinemaTicketInvoiceMeta, UserIspInfo, CreateAccessTokenResponse,
} from './types';
import getConfig from 'next/config';
import { getUniqId } from 'common/utils/getDeviceInfo';
import { GlobalState } from 'common/CacheProvider';
import lscache from 'lscache';

// eslint-disable-next-line no-undef
const { publicRuntimeConfig } = getConfig();
const BASE_URL = publicRuntimeConfig.NEXT_PUBLIC_API_BASE_URL_SSR;

const publicSession = axios.create();
publicSession.defaults.baseURL = BASE_URL;
publicSession.defaults.timeout = 120000;
publicSession.defaults.paramsSerializer = (params) => qs.stringify(params, { indices: false });
const responseInterCeptor = ({ data, ...response }: AxiosResponse) => ({ ...response, ...data });
publicSession.interceptors.request.use(
  (config) => {
    const VERSION = publicRuntimeConfig.NEXT_PUBLIC_VERSION;
    const cachedState = lscache.get('state') as GlobalState;
    const device_id = getUniqId();
    config.headers['Content-Type'] = 'application/json';
    // config.headers['x-server-api-call'] = 'x-server-api-call';
    config.headers['X-platform'] = 'Web';
    if (VERSION) {
      config.headers['version-number'] = VERSION;
    }
    if (cachedState && cachedState.user) {
      config.headers['user-id'] = cachedState.user?.id;
    }
    if (device_id) {
      config.headers['device-id'] = device_id;
    }
    return config;
  },
  (config) => {
    return config;
  },
);
publicSession.interceptors.response.use(responseInterCeptor);
export { publicSession };

export const fetchHomePageWidgets = ({ count = 5, offset = 0 } = {}) => (
  publicSession.get<never, ResponseContainer<Widget[]>>(
    '/widgets/home-page',
    {
      params: { count, offset },
    },
  )
);

export const fetchCategories = () => (
  publicSession.get<never, ResponseContainer<CategoryList[]>>('/categories')
);
export const fetchCategory = (id: any) => (
  publicSession.get<never, ResponseContainer<Category>>(`/categories/${id}`)
);
export const fetchHyperlinksMenus = () => (
  publicSession.get<never, ResponseContainer<HyperLinkMenuList[]>>('/hyperlinkMenus/')
);

export const fetchVideoContents = (params?: ContentRequestParams, session: AxiosInstance = publicSession) => (
  session.get<never, ResponseContainer<Content[]>>('/video-contents', {
    params,
  })
);

export const fetchContentById = (id: string, session: AxiosInstance = publicSession) => (
  session.get<never, ResponseContainer<Content>>(`/video-contents/${id}`)
);
export const fetchArtistById = (id: string) => (
  publicSession.get<never, ResponseContainer<Person>>(`/artists/${id}`)
);

export const fetchContentListItems = (id: string) => (
  publicSession.get<Content[], ResponseContainer<Content[]>>(`/video-contents/${id}/items`, { params: { count: 30 } })
);

// export const fetchContentArtists = ({ id, count = 50, offset = 0 }) => (
//   publicSession.get<never, ResponseContainer<VideoArtist[]>>(
//     `/video-contents/${id}/artists`, { params: { count, offset } },
//   )
// );

// export const fetchSeriesData = (id: string) => (
//   publicSession.get<never, ResponseContainer<Content>>(`/video-contents/${id}/series`)
// );
// export const fetchArtistData = (id: string) => (
//   publicSession.get<never, ResponseContainer<Content>>(`/video-contents/${id}/artists`)
// );

export const fetchSystemConfigurations = () => (
  publicSession.get<never, ResponseContainer<ApplicationsConfigurations>>('/client-configurations/')
);

export const fetchContentComments = ({ id, count, offset }: { id: string, count?: number, offset?: number; }) => (
  publicSession.get<never, ResponseContainer<Comment[]>>(`/video-contents/${id}/comments`, { params: { count, offset } })
);

// export const fetchSurveyGroup = ({ referenceId }) => (
//   publicSession.get<never, ResponseContainer<SurveyGroup>>(`/survey-groups/${referenceId}`)
// );

export const aggregateContentData = (id: string) => (
  publicSession.get<never, ResponseContainer<ContentAggregate>>(`/video-contents-aggregate/${id}`)
);

// export const fetchCinemaOnline = () => (
//   publicSession.get<never, ResponseContainer<Category>>('/cinema-online')
// );
export const fetchCinemaOnline = () => (
  publicSession.get<never, ResponseContainer<Category>>('/cinema-online/anonymous')
);

export const fetchLiveStream = () => (
  publicSession.get<never, ResponseContainer<Category>>('/live')
);

export const fetchCinemaTicketInvoice = (id: string) => (
  publicSession.get<never, ResponseContainer<CinemaTicketInvoice, CinemaTicketInvoiceMeta>>(`/video-contents/${id}/ticket-invoice`)
);

export const getUserIspPublic = () => (
  publicSession.get<never, ResponseContainer<UserIspInfo>>('/users/_self/ip')
);

export const loginSso = (code: string, state: string, expiration_token: string) => (
  publicSession.get<never, ResponseContainer<CreateAccessTokenResponse>>('/sso/login', { params: { code, state, expiration_token } })
);
